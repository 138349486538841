import { FiTrash } from 'react-icons/fi';
import { Button, ConfirmationDialog } from '../index';

export function ConfirmationDialogDelete({ onDelete, loading, title = '', id }) {
  return (
    <ConfirmationDialog
      icon="danger"
      title={`Borrar ${title}`}
      body="¿Está seguro de que desea eliminar?"
      triggerButton={
        <Button variant="dangerInversed" size="sm" startIcon={<FiTrash className="h-4 w-4" />}>
          Borrar
        </Button>
      }
      confirmButton={
        <Button isLoading={loading} type="button" variant="danger" onClick={() => onDelete(id)}>
          Borrar
        </Button>
      }
    />
  );
}
