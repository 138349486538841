import BOTONVERDE from 'assets/img/BOTONVERDELOGOBLANCO.png';
import { Link } from 'react-router-dom';

const Logo = () => (
  <Link className="flex justify-center mr-3 w-full text-white" to=".">
    <img className="h-20 mt-1" src={BOTONVERDE} alt="Workflow" />
  </Link>
);

export default Logo;
