import { useMutation, useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';

const buildForm = (data) => {
  const form = new FormData();
  Object.entries(data).map((item) => {
    form.append(item[0], item[1]);
    return;
  });

  return form;
};

export function apiBuilderPaths({ urlUpdate, urlCreate, urlDelete, urlGetAll, urlGetOne }) {
  const create = (data) => axios.post(`/${urlCreate}/store`, buildForm(data));
  const useCreate = () =>
    useMutation({
      onError: (_, __, context) => {
        if (context?.previousData) {
          queryClient.setQueryData(urlGetAll, context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(urlGetAll);
      },
      mutationFn: create,
    });

  const deleteFn = (id) => axios.delete(`/${urlDelete}/${id}/delete`);
  const useDelete = () =>
    useMutation({
      onError: (_, __, context) => {
        if (context?.previousData) {
          queryClient.setQueryData(urlGetAll, context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(urlGetAll);
      },
      mutationFn: deleteFn,
    });

  const getAll = () => axios.get(`/${urlGetAll}`);
  const useGetAll = () =>
    useQuery({
      queryKey: [`${urlGetAll}`],
      queryFn: () => getAll(),
    });

  const getOne = () => axios.get(`/${urlGetOne}`);
  const useGetOne = () =>
    useQuery({
      queryKey: [`${urlGetOne}`],
      queryFn: () => getOne(),
    });

  const update = ({ id, data }) => axios.post(`/${urlUpdate}/${id}/update`, data);
  const useUpdate = () =>
    useMutation({
      onError: (_, __, context) => {
        if (context?.previousData) {
          queryClient.setQueryData([urlGetAll, context?.previousData.id], context?.previousData);
        }
      },
      onSuccess: (data) => {
        queryClient.refetchQueries([urlGetAll, data?.id]);
      },
      mutationFn: update,
    });

  return { useGetAll, useGetOne, useCreate, useUpdate, useDelete };
}

export function apiBuilder(queryName) {
  const create = (data) => axios.post(`/${queryName}/store`, data);
  const useCreate = () =>
    useMutation({
      onError: (_, __, context) => {
        if (context?.previousData) {
          queryClient.setQueryData(queryName, context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryName);
      },
      mutationFn: create,
    });

  const deleteFn = (id) => axios.delete(`/${queryName}/${id}/delete`);
  const useDelete = () =>
    useMutation({
      onError: (_, __, context) => {
        if (context?.previousData) {
          queryClient.setQueryData(queryName, context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryName);
      },
      mutationFn: deleteFn,
    });

  const getAll = () => axios.get(`/${queryName}`);
  const useGetAll = () =>
    useQuery({
      queryKey: [`${queryName}`],
      queryFn: () => getAll(),
    });

  const getOne = (id) => axios.get(`/${queryName}/${id}/show`);
  const useGetOne = () =>
    useQuery({
      queryFn: getOne,
    });

  const update = ({ id, data }) => axios.post(`/${queryName}/${id}/update`, data);
  const useUpdate = () =>
    useMutation({
      onError: (_, __, context) => {
        if (context?.previousData) {
          queryClient.setQueryData([queryName, context?.previousData.id], context?.previousData);
        }
      },
      onSuccess: (data) => {
        queryClient.refetchQueries([queryName, data?.id]);
      },
      mutationFn: update,
    });

  return { useGetAll, useGetOne, useCreate, useUpdate, useDelete };
}
