import { RiLockPasswordLine } from 'react-icons/ri';
import { Button } from 'components/Elements';
import { ConfirmationDialog } from 'components/Elements/ConfirmationDialog';

import { useNotificationStore } from 'stores/notifications';
import { useState } from 'react';
import { axios } from 'lib/axios';

export const resetPass = async (id, objPass) => {
  const { data } = await axios.post(`/users/${id}/resetpassword`, objPass);
  return data;
};

export const ResetPass = ({ userId }) => {
  const { addNotification } = useNotificationStore();
  const [done, setDone] = useState(false);

  const onSubmit = async () => {
    try {
      await resetPass(userId, { password: 'botonverde', password_confirmation: 'botonverde' });
      addNotification({
        type: 'success',
        title: 'Reseteada exitosamente!',
      });
      setDone(true);
    } catch (error) {}
  };

  return (
    <ConfirmationDialog
      title="Reestablecer contraseńa"
      isDone={done}
      body={
        <p>
          La contraseña por defecto es <b> botonverde </b>
        </p>
      }
      triggerButton={
        <Button variant="inverse" startIcon={<RiLockPasswordLine className="w-4 h-4" />} size="sm">
          Restablecer contraseña
        </Button>
      }
      confirmButton={
        <span onClick={onSubmit}>
          <Button
            variant="dangerInversed"
            startIcon={<RiLockPasswordLine className="w-4 h-4" />}
            size="sm"
          >
            Restablecer contraseña
          </Button>
        </span>
      }
    />
  );
};
