import { form, columns } from './fields';
import { schema } from './validators';

const values = {
  moduleName: 'Estados de diagnosticos',
  persmission: 'read',
  apiUrl: 'estados/diagnosticos',
  pathUrl: '/diagnostics-status',
  columns,
  schemaUpdate: schema,
  schemaCreate: schema,
  saveProps: {
    arrFields: form,
    title: 'Estado diagnostics',
    formId: 'estados/diagnosticos',
  },
};

export default values;
