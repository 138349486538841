import SideNavigation from './SideNavigation';
import Logo from './Logo';

const Sidebar = ({ user: { user } }) => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center h-16 flex-shrink-0 px-4 bg-green-700">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-green-700 space-y-1">
              <SideNavigation permissions={user?.modulos || []} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
