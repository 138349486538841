import { Enabled } from 'components/Elements/Enabled';

export const columns = [
  {
    Header: 'Nombre',
    accessor: 'nombre',
  },
  {
    Header: 'Institución',
    Cell: ({ row: { original } }) => <div> {original.institucion?.nombre || ''} </div>,
  },
  {
    Header: 'Estatus',
    Cell: ({ row: { original } }) => (
      <Enabled active={original.activo} item={original} queryName="comites" />
    ),
  },
];

export const form = [
  {
    type: 'text',
    label: 'Nombre',
    name: 'nombre',
    placeholder: 'Nombre',
    required: true,
  },
  {
    type: 'select',
    label: 'Institución',
    name: 'institucion',
    placeholder: 'Institución',
    queryName: 'instituciones',
    required: true,
  },
  {
    type: 'textarea',
    label: 'Descripción',
    name: 'descripcion',
    placeholder: 'Descripción',
    required: true,
  },
];

export const fieldsHidden = {
  institucion: 'institucion',
};
