import { lazyImport } from 'utils/lazyImport';

const { RecordRoutes } = lazyImport(() => import('pages/records/details'), 'RecordRoutes');
const { RecordList } = lazyImport(() => import('pages/records/list'), 'RecordList');

const routes = [
  {
    path: '/records/*',
    element: <RecordRoutes />,
  },
  {
    path: '/records',
    element: <RecordList />,
  },
];

export default routes;
