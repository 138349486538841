import { QueryClient } from 'react-query';

// const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryConfig = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,

    // cache first
    // refetchOnmount: true,
    // refetch when reconnect
    // refetchOnReconnect: false,
    // staleTime: twentyFourHoursInMs,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
