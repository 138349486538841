const storagePrefix = 'botonverde_react_';

export const storage = {
  getToken: () => JSON.parse(window.localStorage.getItem(`${storagePrefix}token`)),
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
};
