import { PageBuilder } from '../PageBuilder';
import { crudActions } from 'hooks/useCRUD';
import { Spinner } from 'components/Elements';
import { memo } from 'react';

export const PageBuilding = memo(
  ({
    path,
    paths,
    schemaCreate,
    schemaUpdate,
    saveProps,
    columns,
    fieldsHidden,
    keyDefaultValue,
    dontCreate = false,
    dontUpdate = false,
    dontDelete = false,
    maskDefaultValues,
    maskSaveData = (data) => data,
    maskDataShow = (data) => data,
  }) => {
    const [{ data, isLoading, filter }, actions] = crudActions(path, paths);

    if (isLoading) {
      return (
        <div className="w-full h-48 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      );
    }

    if (!data) return null;

    return (
      <PageBuilder
        data={data?.data || []}
        keyDefaultValue={keyDefaultValue}
        filter={filter}
        schemaCreate={schemaCreate}
        schemaUpdate={schemaUpdate}
        columns={columns}
        fieldsHidden={fieldsHidden}
        saveProps={saveProps}
        dontCreate={dontCreate}
        dontUpdate={dontUpdate}
        dontDelete={dontDelete}
        maskDefaultValues={maskDefaultValues}
        maskSaveData={maskSaveData}
        maskDataShow={maskDataShow}
        {...actions}
      />
    );
  }
);
