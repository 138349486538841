import { PageBuilding } from 'components/Builders/PageBuilding';
import { ContentLayout } from 'components/Layout/ContentLayout';

import categories from './accessories/categories';
import commitess from './accessories/commitees';
import casesStatus from './accessories/case-status';
import maritalStatus from './accessories/marital-status';
import diagnosticStatus from './accessories/diagnostic-status';
import housingStatus from './accessories/housing-status';
import users from './accessories/users';
import affiliations from './accessories/affiliations';
import deadlines from './accessories/deadlines';
import priority from './accessories/priority';
import familySituation from './accessories/family-situation';
import caseSolution from './accessories/case-solution';

const jsonPages = [
  categories,
  commitess,
  casesStatus,
  maritalStatus,
  diagnosticStatus,
  housingStatus,
  users,
  affiliations,
  deadlines,
  priority,
  familySituation,
  caseSolution,
];

export const buildPages = (pages) => {
  return pages.map((page) => ({
    path: page.pathUrl,
    element: (
      <ContentLayout title={page.moduleName}>
        <div className="mt-4">
          <PageBuilding
            path={page.apiUrl}
            paths={page.apiUrls}
            keyDefaultValue={page.keyDefaultValue}
            fieldsHidden={page.fieldsHidden || null}
            columns={page.columns}
            schemaCreate={page.schemaCreate}
            schemaUpdate={page.schemaUpdate}
            saveProps={page.saveProps}
            dontCreate={page.dontCreate}
            dontUpdate={page.dontUpdate}
            dontDelete={page.dontDelete}
            maskDefaultValues={page.maskDefaultValues}
            maskSaveData={page.maskSaveData}
            maskDataShow={page.maskDataShow}
          />
        </div>
      </ContentLayout>
    ),
  }));
};

export const pagesBuilding = () => {
  return buildPages(jsonPages);
};
