export const StatCard = ({ title, value, bgColor = 'green' }) => (
  <div className="p-4 bg-white transition-shadow duration-200 border rounded-md shadow-sm hover:shadow-lg ">
    <div className="flex items-center justify-between ">
      <span className="text-gray-900">{title || 'Title'}</span>
      <span className="inline-block px-2 text-lg text-white bg-green-600 rounded mr-2">
        {value || '0'}
      </span>
    </div>
  </div>
);
