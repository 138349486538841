import { form, columns } from './fields';
import { schema } from './validators';

const values = {
  moduleName: 'Estados de viviendas',
  persmission: 'read',
  apiUrl: 'estados/viviendas',
  pathUrl: '/housings-states',
  columns,
  schemaUpdate: schema,
  schemaCreate: schema,
  saveProps: {
    arrFields: form,
    title: 'Estado viviendas',
    formId: 'estados/viviendas',
  },
};

export default values;
