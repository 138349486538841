import PropTypes from 'prop-types';

import { Table, Button } from 'components/Elements';
import { useDisclosure } from 'hooks/useDiscloure';
import { DynamicFormDrawer } from 'components/Form';
import { FiPlus } from 'react-icons/fi';

import { deleteUpdateBuilder } from '../DeleteUpdateBuilder';
import { usePermissions } from 'hooks/usePermissions';

export function PageBuilder({
  data = [],
  columns,
  filter,
  setFilter,
  saveProps,
  onCreate,
  onUpdate,
  onDelete,
  schemaCreate,
  schemaUpdate,
  dontCreate = false,
  dontUpdate = false,
  dontDelete = false,
  fieldsHidden = {},
  maskDefaultValues = null,
  maskSaveData = (data) => data,
  maskDataShow = (data) => data,
}) {
  const permissions = usePermissions();

  const { isOpen: isOpen2, open: open2, close: close2 } = useDisclosure();

  const create = async (data) => {
    onCreate(data);
    close2();
  };

  const allColumns = [
    ...columns,
    ...deleteUpdateBuilder({
      ...saveProps,
      onDelete,
      onUpdate,
      dontUpdate,
      dontDelete,
      fieldsHidden,
      maskDefaultValues,
      schemaUpdate,
      permissions,
      maskDataShow,
      maskSaveData,
    }),
  ];

  return (
    <>
      <div className="flex justify-between fadeIn">
        <input
          className="px-2 text-xs border-2 border-green-600 rounded outline-none focus:border-green-400"
          placeholder="Filtrar resultados..."
          onChange={(e) => setFilter(e.target.value)}
        />

        {!dontCreate && permissions.create && (
          <DynamicFormDrawer
            onOpen={open2}
            isOpen={isOpen2}
            onClose={close2}
            formId={saveProps.formId}
            onSubmit={create}
            maskSaveData={maskSaveData}
            schemaValidator={schemaCreate}
            renderFooter={
              <Button form={saveProps.formId} type="submit" size="sm">
                Crear
              </Button>
            }
            {...saveProps}
          >
            <Button type="submit" size="sm" startIcon={<FiPlus className="w-4 h-4" />}>
              Crear
            </Button>
          </DynamicFormDrawer>
        )}
      </div>
      <div className="mt-4">
        <Table onClickRow={({ original }) => {}} data={data} columns={allColumns} filter={filter} />
      </div>
    </>
  );
}

PageBuilder.prototypes = {
  fieldsHidden: PropTypes.objectOf(PropTypes.object),
  dontCreate: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  filter: PropTypes.array,
  setFilter: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  schemaCreate: PropTypes.objectOf(PropTypes.object),
  schemaUpdate: PropTypes.objectOf(PropTypes.object),
  saveProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.object),
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']),
    arrFields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
};
