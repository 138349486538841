import { form, columns } from './fields';
import { schema } from './validators';

const values = {
  moduleName: 'Soluciones de casos',
  persmission: 'read',
  apiUrl: 'soluciones/casos',
  pathUrl: '/cases-solutions',
  columns,
  schemaUpdate: schema,
  schemaCreate: schema,
  saveProps: {
    arrFields: form,
    title: 'Solucion de caso',
    formId: 'soluciones/casos',
  },
};

export default values;
