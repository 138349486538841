import PropTypes from 'prop-types';

import { InputField } from './InputField';
import { TextAreaField } from './TextareaField';
import { CustomSelect } from './ReactSelect';
import { classNameInput } from '.';
import { FieldWrapper } from './FieldWrapper';

export function DynamicField({
  type = 'text',
  options = [],
  registration,
  label,
  placeholder = '',
  defaultValue,
  queryName,
  error = {},
  setValue = null,
  name,
  required = false,
  object = false,
}) {
  if (type === 'select') {
    return (
      <CustomSelect
        required={required}
        object={object}
        name={name}
        label={label}
        error={error}
        options={options}
        setValue={setValue}
        queryName={queryName}
        placeholder={placeholder}
        idSelected={defaultValue?.id ? defaultValue.id : defaultValue}
      />
    );
  }
  if (type === 'textarea') {
    return (
      <TextAreaField
        type={type}
        label={label}
        error={error}
        required={required}
        placeholder={placeholder}
        registration={registration}
      />
    );
  }

  if (type === 'radio') {
    return (
      <FieldWrapper label={label} error={error} required={required}>
        {options.map((item) => (
          <div onClick={() => setValue(name, item[name])}>
            <input type="radio" checked={!!item[name] === !!defaultValue} />
            <label> {item.label} </label>
          </div>
        ))}
      </FieldWrapper>
    );
  }

  if (type === 'file') {
    return (
      <FieldWrapper error={error} label={label} required={required}>
        <input
          className={classNameInput}
          type={type}
          onChange={({ target }) => target.files && setValue(name, target.files[0])}
        />
      </FieldWrapper>
    );
  }

  if (type === 'date') {
    return (
      <FieldWrapper error={error} label={label} required={required}>
        <input
          type="date"
          className={classNameInput}
          defaultValue={defaultValue}
          onChange={({ target }) => setValue(name, target.value)}
        />
      </FieldWrapper>
    );
  }

  return (
    <InputField
      type={type}
      label={label}
      error={error}
      required={required}
      defaultValue={defaultValue}
      placeholder={placeholder}
      registration={registration}
    />
  );
}

DynamicField.prototypes = {
  setValue: PropTypes.func,
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  registration: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.object),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
