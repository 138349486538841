import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

export const Form = ({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
  maskSaveData = (data) => data,
}) => {
  const save = (data) => (mask) => {
    onSubmit(mask(data));
  };

  const methods = useForm({
    ...options,
    resolver: schema && yupResolver(schema),
  });

  return (
    <form
      className={clsx('space-y-5 w-full', className)}
      onSubmit={methods.handleSubmit((data) => save(data)(maskSaveData))}
      id={id}
    >
      {children(methods)}
    </form>
  );
};
