import { Route, Routes, Navigate } from 'react-router-dom';

import { ForgotPassword } from './forgotPassword';
import { Login } from './login';
import { Register } from './register';

export const AuthRoutes = () => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="register" element={<Register />} />
    <Route path="forgot-password" element={<ForgotPassword />} />
    <Route path="*" element={<Navigate to="login" />} />
  </Routes>
);
