import { getAuthorizedRoutes } from './Nav';
import { Disclosure } from 'components/Elements/';

import MenuItem from './MenuItem';
import clsx from 'clsx';

const SideNavigation = ({ permissions = [] }) => {
  return (
    <>
      {getAuthorizedRoutes(permissions).map((item) =>
        item.dropdown ? (
          <Disclosure key={item.dropdown} title={item.dropdown} icon={item.icon}>
            {item.subroutes.map((route, i) => (
              <div className="px-2" key={i}>
                <MenuItem key={route.name} to={route.to}>
                  {route.name}
                </MenuItem>
              </div>
            ))}
          </Disclosure>
        ) : (
          <MenuItem key={item.name} to={item.to}>
            <item.icon
              className={clsx('text-white group-hover:text-gray-300', 'mr-4 flex-shrink-0 h-6 w-6')}
              aria-hidden="true"
            />
            {item.name}
          </MenuItem>
        )
      )}
    </>
  );
};

export default SideNavigation;
