import { FiGrid, FiLifeBuoy, FiSliders, FiCommand } from 'react-icons/fi';
import { FaHistory } from 'react-icons/fa';
import { GoReport } from 'react-icons/go';

export const navigation = [
  { name: 'Dashboard', to: '/', icon: FiGrid, permission: 'dashboard' },
  {
    dropdown: 'Servicios',
    icon: FiLifeBuoy,
    subroutes: [
      { name: 'Instituciones', to: './institutions', permission: 'instituciones' },
      { name: 'Comités', to: './committees', permission: 'comites' },
      {
        name: 'Miembros de comités',
        to: './committees-members',
        permission: 'comites',
      },
      { name: 'Responsables de servicios', to: './service-responsible', permission: 'servicios' },
      { name: 'Servicios', to: './services', permission: 'servicios' },
      { name: 'Programas', to: './programs', permission: 'programas' },
    ],
  },
  {
    dropdown: 'Configuraciones',
    permission: 'administracion',
    icon: FiSliders,
    subroutes: [
      { name: 'Plazos', to: './deadlines' },
      { name: 'Categorías', to: './categories' },
      { name: 'Filiaciones', to: './affiliations' },
      { name: 'Estado civil', to: './marital-status' },
      { name: 'Estados de casos', to: './cases-status' },
      { name: 'Estados de diagnósticos', to: './diagnostics-status' },
      { name: 'Estados de viviendas', to: './housings-states' },
      { name: 'Situación familiar', to: './family-situation' },
      { name: 'Soluciones de casos', to: './cases-solutions' },
      { name: 'Prioridades', to: './priority' },
    ],
  },
  {
    dropdown: 'Usuarios',
    icon: FiCommand,
    subroutes: [
      {
        name: 'Usuarios',
        to: './users',
        permission: 'usuarios',
      },
      { name: 'Roles', to: './role', permission: 'roles' },
    ],
  },
  { name: 'Casos', to: './records', permission: 'casos', icon: FiSliders },
  {
    dropdown: 'Bitacoras',
    icon: FaHistory,
    subroutes: [
      { name: 'Bitacoras ciudadano', to: './bitacoras', permission: 'bitacoras' },
      {
        name: 'Bitacora sistema',
        to: './bitacoras-app',
        permission: 'bitacoras',
      },
    ],
  },
  // { name: 'Reportes', to: './reports', permission: 'reportes', icon: GoReport },
];

export const getAuthorizedRoutes = (modules = []) => {
  const reducedPermits = modules.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.modulo]: curr,
    }),
    {}
  );

  return navigation.filter((item) => {
    if (item.permission === 'dashboard') return true;
    if (reducedPermits[item.permission]) return true;
    if (item.subroutes && !item.permission) {
      const authorizedSub = item.subroutes.filter((sub) => reducedPermits[sub.permission]);
      item.subroutes = authorizedSub;

      if (authorizedSub.length) return true;
      if (!authorizedSub.length) return false;
    }
    return false;
  });
};
