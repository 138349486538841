import * as React from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from 'components/Elements';
import { Form, InputField } from 'components/Form';
import { useAuth } from 'lib/auth';

const schema = z.object({
  email: z.string().min(1, 'Required'),
  name: z.string().min(1, 'Required'),
  role_id: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'Expected number, received a string',
  }),
  password: z.string().min(1, 'Required'),
  password_confirmation: z.string().min(1, 'Required'),
});

export const RegisterForm = ({ onSuccess }) => {
  const { register, isRegistering } = useAuth();

  const onSubmit = async (data) => {
    const res = await register(data);
    if (res) {
      onSuccess();
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Correo electrónico"
              error={formState.errors.email}
              registration={register('email')}
            />
            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full bg-blue-900">
                Enviar correo
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm p-1.5 transition-all">
          <Link to="../login" className="font-medium text-blue-800 hover:text-blue-500">
            Volver al Login
          </Link>
        </div>
      </div>
    </div>
  );
};
