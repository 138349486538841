import PropTypes from 'prop-types';

import { DynamicField } from './DynamicField';
import { Form } from './Form';

const getQueryName = (query, dependent, watch) => {
  const watched = watch(dependent);
  if (!dependent) return query;
  if (dependent && !watched) return '';
  if (dependent === 'comite')
    return `${query}/${watched?.id ? watched?.id : watched}/miembros?activo=true`;
  return `${query}${query.includes('?') ? '&' : '?'}${dependent}=${watched}`;
};

export function DynamicForm({
  arrFields = [],
  formId,
  options = {},
  onSubmit,
  schemaValidator,
  fieldsHidden,
  className = '',
  maskSaveData = (data) => data,
}) {
  return (
    <>
      <Form
        className={className}
        id={formId}
        options={options}
        onSubmit={onSubmit}
        schema={schemaValidator}
        maskSaveData={maskSaveData}
      >
        {(methods) =>
          arrFields
            .filter((item) => !(fieldsHidden || {})[item.name])
            .map((field) => (
              <DynamicField
                required={field.required}
                key={field.label}
                type={field.type}
                label={field.label}
                options={field.options}
                placeholder={field.placeholder}
                queryName={getQueryName(field.queryName, field.dependent, methods.watch)}
                name={field.name}
                defaultValue={methods.watch(field.name)}
                registration={{ ...methods.register(field.name) }}
                error={methods.formState?.errors[field.name]}
                setValue={
                  ['select', 'radio', 'file'].includes(field.type) ? methods.setValue : null
                }
              />
            ))
        }
      </Form>
    </>
  );
}

DynamicForm.prototypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.object),
  schemaValidator: PropTypes.objectOf(PropTypes.object).isRequired,
  arrFields: PropTypes.arrayOf(
    PropTypes.shape({
      queryName: PropTypes.string,
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
      ),
      registration: PropTypes.func.isRequired,
      error: PropTypes.objectOf(PropTypes.object),
      label: PropTypes.string,
      placeholder: PropTypes.string,
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
};
