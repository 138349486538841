import { Disclosure as HUIDisclosure, Transition } from '@headlessui/react';
import { createElement } from 'react';

export const Disclosure = ({ title, icon, children }) => {
  return (
    <HUIDisclosure>
      {({ open }) => (
        <>
          <HUIDisclosure.Button
            className={`flex items-center w-full px-2 py-2 text-sm font-medium text-left ${
              open && 'bg-gray-900'
            } text-white rounded-lg hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
          >
            {createElement(icon, {
              className: `${
                open ? '' : 'transform rotate-180'
              } h-6 w-6 text-white transition-all duration-200 mr-4`,
            })}
            <span>{title}</span>
          </HUIDisclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-100 ease-in"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <HUIDisclosure.Panel>
              {' '}
              <div className="bg-green-500 rounded-lg">{children}</div>{' '}
            </HUIDisclosure.Panel>
          </Transition>
        </>
      )}
    </HUIDisclosure>
  );
};
