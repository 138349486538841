import { Outlet } from 'react-router-dom';

import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { pagesBuilding } from '../cruds';

import AuthCitizen from '../providers/auth-citizen';
import GetPermissions from '../providers/get-permissions';

import recordsRoutes from 'pages/records';

const { Dashboard } = lazyImport(() => import('pages/misc'), 'Dashboard');
const { MeRoutes } = lazyImport(() => import('pages/me'), 'MeRoutes');
const { NotFound } = lazyImport(() => import('pages/misc'), 'NotFound');
const { Institution } = lazyImport(() => import('pages/institutions'), 'Institution');
const { Programs } = lazyImport(() => import('pages/programs'), 'Programs');
const { Services } = lazyImport(() => import('pages/services'), 'Services');
const { ServiceResponsible } = lazyImport(() => import('pages/service-responsible'), 'ServiceResponsible');
const { ServicePrograms } = lazyImport(() => import('pages/services-programs'), 'ServicePrograms');
const { HousingState } = lazyImport(() => import('pages/housing-states'), 'HousingState');
const { Roles } = lazyImport(() => import('pages/roles'), 'Roles');
const { Bitacora } = lazyImport(() => import('pages/bitacora'), 'Bitacora');
const { BitacoraApp } = lazyImport(() => import('pages/bitacora-app'), 'BitacoraApp');
const { Reports } = lazyImport(() => import('pages/reports'), 'Reports');
const { DiagnosticStatus } = lazyImport(
  () => import('pages/diagnostics-status'),
  'DiagnosticStatus'
);
const { CommitteesMembers } = lazyImport(
  () => import('pages/committees-members'),
  'CommitteesMembers'
);

export const SpinnerFallback = () => (
  <div className="fadeIn h-screen w-auto flex items-center justify-center transition-all duration-200">
    <Spinner size="xl" />
  </div>
);

const App = () => (
  <MainLayout>
    <AuthCitizen>
      <GetPermissions>
        <Suspense fallback={<SpinnerFallback />}>
          <div className="fadeIn">
            <Outlet />
          </div>
        </Suspense>
      </GetPermissions>
    </AuthCitizen>
  </MainLayout>
);

const pages = pagesBuilding();

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      ...pages,
      ...recordsRoutes,
      {
        path: '/',
        element: <Dashboard />,
      },

      {
        path: '/me/*',
        element: <MeRoutes />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: '/institutions',
        element: <Institution />,
      },
      {
        path: '/committees-members',
        element: <CommitteesMembers />,
      },
      {
        path: '/programs',
        element: <Programs />,
      },
      {
        path: '/services',
        element: <Services />,
      },
      {
        path: '/service-responsible',
        element: <ServiceResponsible />,
      },
      {
        path: '/services-programs',
        element: <ServicePrograms />,
      },
      {
        path: '/diagnostics-status',
        element: <DiagnosticStatus />,
      },
      {
        path: '/housings-states',
        element: <HousingState />,
      },
      {
        path: '/role',
        element: <Roles />,
      },
      {
        path: '/bitacoras',
        element: <Bitacora />,
      },
      {
        path: '/bitacoras-app',
        element: <BitacoraApp />,
      },
      {
        path: '/reports',
        element: <Reports />,
      },
    ],
  },
];
