import { useMutation } from 'react-query';

import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';

const useToggle = (queryName, putPath) => {
  const tooglefn = (id) => axios.post(`/${queryName}/${id}/toggle`);

  return () =>
    useMutation({
      onError: (_, __, context) => {
        if (context?.previousData) {
          queryClient.setQueryData(putPath || queryName, context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(putPath || queryName);
      },
      mutationFn: tooglefn,
    });
};

export function Enabled({ active, item, queryName, putPath }) {
  const toogle = useToggle(queryName, putPath)();
  const onToggle = async () => {
    await toogle.mutateAsync(item.id);
  };

  if (active) {
    return (
      <div onClick={onToggle} className="text-green-500 uppercase">
        activo
      </div>
    );
  }

  return (
    <div onClick={onToggle} className="text-red-500 uppercase">
      {' '}
      inactivo{' '}
    </div>
  );
}
