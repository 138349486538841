import { Enabled } from 'components/Elements/Enabled';

export const columns = [
  {
    Header: 'Nombre',
    accessor: 'nombre',
  },
];

export const form = [
  {
    type: 'text',
    label: 'Nombre',
    name: 'nombre',
    placeholder: 'Nombre',
    required: true,
  },
  {
    type: 'textarea',
    label: 'Descripción',
    name: 'descripcion',
    placeholder: 'Descripción',
    required: true,
  },
];
