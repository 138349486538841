import { useAuth } from 'lib/auth';
import { queryClient } from 'lib/react-query';

export function usePermissions() {
  const { queriesMap } = queryClient.queryCache;
  const { state } = queriesMap[`["roles/permisos"]`];

  const permissions = (state.data?.data || []).reduce(
    (acc, curr) => ({ ...acc, [curr.nombre]: curr }),
    {}
  );

  const {
    user: { user },
  } = useAuth();

  const authActions = user.permisos.reduce((acc, curr) => ({ ...acc, [curr.permiso]: curr }), {});

  return Object.keys(authActions).reduce(
    (acc, curr) => ({ ...acc, [curr]: permissions[curr] }),
    {}
  );
}
