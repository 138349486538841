/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const classNameInput =
  'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm';

export const InputField = (props) => {
  const {
    type = 'text',
    label,
    className,
    registration,
    error,
    placeholder,
    defaultValue,
    required,
  } = props;
  return (
    <FieldWrapper label={label} error={error} required={required}>
      <input
        type={type}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={clsx(classNameInput, className)}
        {...registration}
      />
    </FieldWrapper>
  );
};
