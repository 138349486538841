import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Button } from 'components/Elements';
import { Notifications } from 'components/Notifications';
import { AuthProvider } from 'lib/auth';
import { queryClient } from 'lib/react-query';

const ErrorFallback = () => (
  <div
    className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
    role="alert"
  >
    <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
    <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
      Refresh
    </Button>
  </div>
);

export const AppProvider = ({ children }) => (
  <HelmetProvider>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <Notifications />
        <AuthProvider>
          <Router>{children}</Router>
        </AuthProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ErrorBoundary>
  </HelmetProvider>
);
