import { Enabled } from 'components/Elements/Enabled';

export const columns = [
  {
    Header: 'Nombre',
    accessor: 'nombre',
  },
  {
    Header: 'Estatus',
    accessor: 'activo',
    sortType: ((rowA, rowB, id, desc) => {
      if (rowA.values[id] > rowB.values[id]) return 1; 
      if (rowB.values[id] > rowA.values[id]) return -1;
       return 0;
}),
    Cell: ({ row: { original } }) => (
      <Enabled active={original.activo} item={original} queryName="estados/diagnosticos" />
    ),
  },
];

export const form = [
  {
    type: 'text',
    label: 'Nombre',
    name: 'nombre',
    placeholder: 'Nombre',
    required: true,
  },
  {
    type: 'textarea',
    label: 'Descripción',
    name: 'descripcion',
    placeholder: 'Descripción',
    required: true,
  },
];
