import { form, columns } from './fields';
import { schema } from './validators';

const values = {
  moduleName: 'Prioridades',
  persmission: 'read',
  apiUrl: 'prioridades',
  pathUrl: '/priority',
  columns,
  schemaUpdate: schema,
  schemaCreate: schema,
  saveProps: {
    arrFields: form,
    title: 'Prioridades',
    formId: 'Prioridades',
  },
};

export default values;
