import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from 'components/Elements';
import {
  loginWithEmailAndPassword,
  // getUser,
  registerWithEmailAndPassword,
} from 'pages/auth';
import { storage } from 'utils';

async function handleUserResponse(data) {
  if (data) {
    storage.setToken(data);
    return data;
  }
  return null;
}

async function loadUser() {
  if (storage.getToken()) {
    // const data = await getUser();
    const data = storage.getToken();
    return data;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
