/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { FiArchive, FiChevronDown, FiChevronUp, FiMinus } from 'react-icons/fi';
import { useGlobalFilter, useSortBy, useTable, usePagination } from 'react-table';

const ReactTable = ({
  data,
  columns,
  filter,
  onClickRow,
  showPagination = true,
  pageSize: size = 10,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageSize: size } },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    setGlobalFilter(filter);
  }, [filter, setGlobalFilter]);

  return (
    <>
    {console.log(headerGroups)}
      <div className="flex flex-col min-w-full overflow-x-auto align-middle rounded-md ">
        <div className="overflow-x-auto overflow-y-auto border-b border-gray-200 rounded-md shadow-md">
          <table
            {...getTableProps()}
            className="min-w-full overflow-x-scroll divide-y divide-gray-200"
          >
            <thead className="bg-gray-50">
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                    >
                      <div className="flex items-center ">
                        {column.render('Header')}
                        {column.isSorted ?   (column.isSortedDesc ? (
                            <FiChevronDown className="w-4 h-4 ml-1 sm:ml-0" />
                          ) : (
                            <FiChevronUp className="w-4 h-4 ml-1 sm:ml-0" />
                          )) : (column.canSort && <FiMinus className="w-4 h-4 ml-1 sm:ml-0" />)
                        }
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => onClickRow && onClickRow(row)}
                    key={row}
                    {...row.getRowProps()}
                    className="text-sm transition-all cursor-pointer hover:shadow-lg"
                  >
                    {row.cells.map((cell) => (
                      <td key={cell} {...cell.getCellProps()} className="px-6 py-4 ">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {showPagination ? (
        <div className="flex justify-end mt-4 space-x-4 pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Página{' '}
            <strong>
              {(pageIndex || 0) + 1} de {(pageOptions || []).length}
            </strong>{' '}
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </>
  );
};

export const Table = ({ data, columns, filter, showPagination, pageSize }) => {
  if (!data?.length || !columns?.length) {
    return (
      <div className="flex flex-col items-center justify-center text-gray-500 bg-white h-80">
        <FiArchive className="w-16 h-16" />
        <h4>No Entries Found</h4>
      </div>
    );
  }

  return (
    <ReactTable
      data={data}
      columns={columns}
      filter={filter}
      pageSize={pageSize}
      showPagination={showPagination}
    />
  );
};
