import PropTypes from 'prop-types';

import { cloneElement } from 'react';
import { Drawer } from 'components/Elements';
import { DynamicForm } from './DynamicForm';

export function DynamicFormDrawer({
  size,
  title,
  isOpen,
  onClose,
  onOpen,
  children,
  arrFields,
  renderFooter = {},
  formId,
  options = {},
  schemaValidator,
  onSubmit,
  fieldsHidden = {},
  maskSaveData = (data) => data,
}) {
  return (
    <>
      {cloneElement(children, { onClick: onOpen })}
      <Drawer
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        renderFooter={() => renderFooter}
      >
        <DynamicForm
          formId={formId}
          options={options}
          onSubmit={onSubmit}
          schemaValidator={schemaValidator}
          fieldsHidden={fieldsHidden}
          arrFields={arrFields}
          maskSaveData={maskSaveData}
        />
      </Drawer>
    </>
  );
}

DynamicFormDrawer.prototypes = {
  fieldsHidden: PropTypes.objectOf(PropTypes.object),
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  renderFooter: PropTypes.element.isRequired,
  options: PropTypes.objectOf(PropTypes.object),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']).isRequired,
  schemaValidator: PropTypes.objectOf(PropTypes.object).isRequired,
  arrFields: PropTypes.arrayOf(
    PropTypes.shape({
      queryName: PropTypes.string,
      setValue: PropTypes.func,
      watch: PropTypes.func,
      required: PropTypes.bool,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};
