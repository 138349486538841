import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { lazyImport } from 'utils';

const { AuthRoutes } = lazyImport(() => import('pages/auth'), 'AuthRoutes');

const Auth = ( )=>(
  <Suspense fallback={<div>Loading...</div>}>
    <div className='fadeIn'>
    <AuthRoutes />
    </div>
  </Suspense>
)

export const publicRoutes = [
  { path: '/auth/*', element: <Auth /> },
  { path: '*', element: <Navigate to="/auth/login" /> },
];


