import { Link, useLocation } from 'react-router-dom';

export default function MenuItem({ to, icon, children }) {
  const location = useLocation();
  const exact = location.pathname.split('/')[1] === to.split('/')[1];

  return (
    <>
      <Link
        className={`${
          exact ? 'bg-gray-900 text-white' : 'text-white'
        }  hover:bg-green-600 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md transition duration-75 ease-in-out`}
        to={to}
      >
        {icon}
        {children}
      </Link>
    </>
  );
}
