import * as yup from 'yup';

export const schemaCreate = yup.object().shape({
  nombre: yup.string().required('Requerido'),
  email: yup.string().email().required('Requerido'),
  role: yup.number().required('Requerido'),
  password: yup.string().required('Requerido'),
  password_confirmation: yup.string().required('Requerido'),
});

export const schemaUpdate = yup.object().shape({
  nombre: yup.string().required('Requerido'),
  role: yup.number().required('Requerido'),
});
