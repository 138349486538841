import { FiEdit } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { DetailBuilder } from 'components/Builders/DetailBuilder';
import { DynamicFormDrawer } from 'components/Form';
import { ConfirmationDialogDelete } from 'components/Elements';
import { useDisclosure } from 'hooks/useDiscloure';
import { Button } from 'components/Elements';

const getDefaultValues = (defaultValues) => {
  const payload = { ...defaultValues };
  for (const name in payload) {
    if (payload[name] && typeof payload[name] === 'object') {
      payload[name] = payload[name].id;
    }
  }
  return payload;
};

export const deleteUpdateBuilder = ({
  maskDefaultValues = (data) => data,
  maskSaveData = (data) => data,
  maskDataShow = (data) => data,
  arrFields,
  permissions,
  formId,
  dontDelete,
  dontUpdate,
  onDelete,
  onUpdate,
  fieldsHidden = {},
  schemaUpdate,
}) => {
  return [
    {
      Header: 'Ver',
      Cell: ({ row: { original } }) => (
        <DetailBuilder labels={arrFields} data={maskDataShow(original)}>
          <AiOutlineEye size={25} className="text-gray-800" />
        </DetailBuilder>
      ),
    },
    {
      Header: 'Opciones',
      accessor: 'id',
      Cell: ({ row: { original } }) => {
        const { isOpen, open, close } = useDisclosure();
        return (
          <div key={original.id} className="flex items-center justify-start gap-2">
            {permissions.update && !dontUpdate && (
              <DynamicFormDrawer
                onOpen={open}
                isOpen={isOpen}
                onClose={close}
                formId={formId}
                options={{
                  defaultValues: { ...(maskDefaultValues || getDefaultValues)(original) },
                }}
                onSubmit={(data) => {
                  onUpdate(data, data.id);
                }}
                maskSaveData={maskSaveData}
                schemaValidator={schemaUpdate}
                fieldsHidden={fieldsHidden}
                arrFields={arrFields}
                renderFooter={
                  <Button form={formId} type="submit" size="sm">
                    Actualizar
                  </Button>
                }
              >
                <Button
                  variant="inverse"
                  type="button"
                  size="sm"
                  startIcon={<FiEdit className="w-4 h-4" />}
                >
                  Actualizar
                </Button>
              </DynamicFormDrawer>
            )}
            {permissions.destroy && !dontDelete && (
              <ConfirmationDialogDelete title="" id={original.id} onDelete={onDelete} />
            )}
          </div>
        );
      },
      disableSortBy: true,
      
    },
  ];
};
