import { form, columns } from './fields';
import { schema } from './validators';

const values = {
  moduleName: 'Estados de casos',
  persmission: 'read',
  apiUrl: 'estados/casos',
  pathUrl: '/cases-status',
  columns,
  schemaUpdate: schema,
  schemaCreate: schema,
  saveProps: {
    arrFields: form,
    title: 'estado de casos',
    formId: 'estados-casos',
  },
};

export default values;
