import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { FieldWrapper } from './FieldWrapper';
import { Spinner } from 'components/Elements';

import Select from 'react-select';

const useGetAll = (queryName) => {
  if (!queryName) return { data: {} };
  const getAll = () => axios.get(`/${queryName}`);

  const { isLoading, data } = useQuery({
    queryKey: [`${queryName}`],
    queryFn: () => getAll(),
  });

  return { isLoading, data };
};
const onFilter = (selecteds, request) => {
  const reduced = selecteds.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
  return request.filter((item) => !reduced[item.id]);
};

export function CustomSelect({
  name,
  setValue,
  idSelected,
  queryName,
  options = [],
  filter = [],
  label = '',
  error,
  placeholder = 'Seleccionar',
  required = false,
  object = false,
}) {
  if (!queryName && !options.length) return <></>;

  const [selected, setSelected] = useState(null);
  const [all, setAll] = useState([]);

  const { data, isLoading } = useGetAll(queryName);
  const valOpt = options && options.length && options;

  useEffect(() => {
    if (valOpt) setAll(valOpt);
    if (data?.data) setAll(data?.data);
  }, [data, isLoading]);

  useEffect(() => {
    const finded =
      idSelected &&
      all.find((item) => `${item.id}` === `${idSelected}` || item.nombre === idSelected);
    if (finded) setSelected(finded);
    if (!finded) setSelected(null);
  }, [idSelected, all]);

  if (isLoading || !data)
    return (
      <div className="fadeIn flex justify-center w-full">
        <Spinner />;
      </div>
    );
  return (
    <FieldWrapper label={label} error={error} required={required}>
      <Select
        value={selected}
        placeholder={placeholder}
        getOptionValue={(item) => item}
        getOptionLabel={(item) => item.nombre}
        options={onFilter(filter, all)}
        onChange={(item) => setValue(name, !object ? item.id : item)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'rgba(110, 231, 183, var(--tw-bg-opacity))',
            primary: 'rgba(5, 150, 105, var(--tw-bg-opacity))',
          },
        })}
      />
    </FieldWrapper>
  );
}
