/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const TextAreaField = (props) => {
  const { label, className, registration, error, placeholder, required } = props;
  return (
    <FieldWrapper label={label} error={error} required={required}>
      <textarea
        placeholder={placeholder}
        className={clsx(
          'appearance-none block w-full h-32 max-h-32 min-h-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
