import { form, columns } from './fields';
import { schema } from './validators';

const values = {
  moduleName: 'Filiaciones',
  persmission: 'read',
  apiUrl: 'filiaciones',
  pathUrl: '/affiliations',
  columns,
  schemaUpdate: schema,
  schemaCreate: schema,
  saveProps: {
    arrFields: form,
    title: 'Filiaciones',
    formId: 'filiaciones',
  },
};

export default values;
