import clsx from 'clsx';

export const FieldWrapper = (props) => {
  const { label, className, error, children, required } = props;
  return (
    <div>
      <label className={clsx('block items-center text-sm font-medium text-gray-700', className)}>
        <div className="flex space-x-2">
          <p>{label}</p>
          {required && <p className="text-red-700 text-xl"> * </p>}
        </div>
        <div className="mt-1">{children}</div>
      </label>
      {error?.message && (
        <div role="alert" aria-label={error.message} className="text-sm font-semibold text-red-500">
          {typeof error.message === 'string' ? error.message : 'Requerido'}
        </div>
      )}
    </div>
  );
};
