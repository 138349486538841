import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from 'components/Elements';
import { Form, InputField } from 'components/Form';
import { useAuth } from 'lib/auth';

const schema = yup.object().shape({
  email: yup.string().email(),
  password: yup.string().required(),
});

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Correo electrónico"
              error={formState.errors.email}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Contraseña"
              error={formState.errors.password}
              registration={register('password')}
            />
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full bg-blue-900">
                Log in
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm text-center border-blue-800 p-1.5 rounded transition-all">
          <Link to="../register" className="font-medium text-blue-900 hover:text-blue-700">
          ¿Olvidó su contraseña?

          </Link>
        </div>
      </div>
    </div>
  );
};
