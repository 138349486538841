import { useEffect } from 'react';
import { apiBuilder } from 'utils/api-builder';
// import { authUser } from 'lib/auth';

const MINUTE_MS = 3300000;
export const citizenToken = 'citizens/getCitizenToken';

export default function AuthTokenCitizen({ children }) {
  //  const { user } =  authUser();
  const { useGetAll } = apiBuilder(citizenToken);
  const { isLoading, refetch } = useGetAll();

  useEffect(() => {
    const interval = setInterval(async () => {
      await refetch();
    }, MINUTE_MS);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  if (isLoading) return <>Obteniendo autorización...</>;
  return children;
}
